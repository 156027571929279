import { createContext, useContext, ReactNode } from "react";
import Axios from "axios";
import notify from "$notify";
import { useQuery as useRQuery, useMutation as RQUseMutation, useQueryClient } from "@tanstack/react-query";
import qs from "qs";
import {isNonNullString,isObj,extendObj,defaultObj,defaultStr} from "$utils";
import { getToken,removeToken } from "$auth/session";
import i18n from "i18next";
import {setQueryParams} from "$utils/uri";
import { useHistory } from "react-router";

export const RETRY_OPTIONS = { cacheTime: 2000,refetchInterval:50000};

export const RETRY_LIST_OPTIONS = {cacheTime:5000,refetchInterval:20000};

export const isValidID  = id=> isNonNullString(id) || typeof id =='number' && true || false;

const isValidNetworkMode = (nMode)=>isNonNullString(nMode) && ['online','always','offlineFirst'].includes(nMode.toLowerCase().trim());
const isValidRetryDelay = (retryDelay) => typeof retryDelay =='number' || typeof retryDelay ==='function'? true : false;

const queries403Cached = {};

/***** override of useQuery
  @see : https://tanstack.com/query/v4/docs/react/guides/migrating-to-react-query-4
  @see : https://tanstack.com/query/v4/docs/react/reference/useQuery for useQuery options
  @param {boolean} handle403, pour la prise en compte du 403
  @param {Array} key, array of key string, The query key to use for this query.The query key will be hashed into a stable hash. See Query Keys for more information. The query will automatically update when this key changes (as long as enabled is not set to false).
  @param {function} queryFn, query function  (context: QueryFunctionContext) => Promise<TData>
  @param {boolean} enabled  Set this to false to disable this query from automatically running.
  @param {string} networkMode 'online' | 'always' | 'offlineFirst
  @param {boolean| number | (failureCount: number, error: TError) => boolean} retry If false, failed queries will not retry by default.If true, failed queries will retry infinitely. If set to a number, e.g. 3, failed queries will retry until the failed query count meets that number.
  @param {boolean} retryOnMount If set to false, the query will not be retried on mount if it contains an error. Defaults to true.
  @param {number | (retryAttempt: number, error: TError) => number} retryDelay  This function receives a retryAttempt integer and the actual Error and returns the delay to apply before he next attempt in milliseconds.  A function like attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000) applies exponential backoff., A function like attempt => attempt * 1000 applies linear backoff.
*/
export const useQuery = (key,queryFn,enabled,networkMode,retry,retryOnMount,retryDelay,...args)=>{
  const options = isObj(key)? Object.assign({},key) : isNonNullString(key)? {queryKey:key.split(",")} : Array.isArray(key)? {queryKey:key} : {};
  key = isNonNullString(key)? key.split(",") : Array.isArray(key)? key : [];
  options.queryKey = Array.isArray(options.queryKey) && options.queryKey.length && options.queryKey || key;
  options.queryFn = typeof queryFn =='function' ? queryFn : typeof options.queryFn =='function'? options.queryFn : undefined; 
  options.enabled = typeof enabled === "boolean" ? enabled : typeof options.enabled =='boolean' ? options.enabled : true;
  options.networkMode = isValidNetworkMode(networkMode) ? networkMode.toLowerCase().trim() : isValidNetworkMode(options.networkMode) ? options.networkMode.toLowerCase().trim() : "online";
  options.retryOnMount = typeof retryOnMount ==='boolean'? retryOnMount : typeof options.retryOnMount =='boolean' ? options.retryOnMount : undefined;
  options.retryDelay = isValidRetryDelay(retryDelay)? retryDelay : isValidRetryDelay(options.retryDelay) ? options.retryDelay : undefined;
  [queryFn,enabled,networkMode,retry,retryOnMount,retryDelay].map((a)=>{
    isObj(a) && extendObj(true,options,a);
  }); 
  args.map((a)=>{
    isObj(a) && extendObj(true,options,a);
  });
  options.retry = typeof retry =='boolean' || typeof retry ==='number' ? retry : typeof options.retry ==='boolean' || typeof options.retry =='number' ? options.retry : undefined;
  options.enabled = !options.queryKey?.length ? false : options.enabled;
  //if not enabled, we have to disabled retry option
  options.retry = !options.enabled ? false : retry || options.retry;
  const {onError} = options;
  const history = useHistory();
  const keyString = key.join(",");
  if(keyString && queries403Cached[keyString]){
    options.retry = false;
  }
  const {handle403} = options;
  delete options.handle403;
  options.onError = (e,...args)=>{
    if(handle403){
      const {response} = isObj(e)? e : {};
      if(isObj(response) && response.status === 403){
        keyString ? queries403Cached[keyString] = true : null;
        setTimeout(()=>{
          history.goBack();///redirection à la page précédente
          keyString && delete queries403Cached[keyString];
        },100);
      }
    }
    if(typeof onError ==='function' && (onError(e,options) === false)) return;
  }
  //const r = !options.enabled ? console.error("unable to get query for options",options) : null;
  return useRQuery(options);
}

export const getRequestHeaders = (headers)=>{
  const r = extendObj(true,{},{
    "Content-Type": "application/json",
    "languageCode" : i18n.resolvedLanguage,
  },headers);
  const token = getToken();
  r["languageCode"] = r["Accept-Language"] = i18n.resolvedLanguage;
  if (token) {
    r.Authorization = `Bearer ${token}`;
  }
  return r;
}
export const getBaseUrl = (uri)=>{
  const baseURL = process.env.REACT_APP_URL_API + "";
  if(isNonNullString(uri)){
    return baseURL.rtrim("/")+"/"+uri.ltrim("/");
  }
  return baseURL;
} 
export const axios = Axios.create({
  baseURL: getBaseUrl(),
  timeout: 30000,
  headers: getRequestHeaders(),
});

axios.interceptors.request.use((config) => {
  // Read token for anywhere, in this case directly from localStorage
  config.headers = getRequestHeaders(config.headers);
  if(config.data instanceof FormData || config?.data?.FormData){
    config.headers['Content-Type'] = 'multipart/form-data';
  }
  return config;
});

export const notifyError = (errorDat,suffix)=>{
  if (errorDat && isNonNullString(errorDat?.message)) {
    const details = Array.isArray(errorDat.details) ? errorDat.details.map(v=>{
      if(isNonNullString(v)){
        return v;
      }
      if(isObj(v)){
        let message = isNonNullString(v.error_message) && v.error_message || null;
        if(!v){
          return "";
        }
        if(isNonNullString(v.param_name)){
          message+=`, param_name:${v.param_name}`;
        }
        if(isNonNullString(v.param_value)){
          message+= `, param_value:${v.param_value}`;
        }
        return message;
      }
    }).join(", ") : errorDat.details;
    const titleText = isNonNullString(errorDat.path) ? `, path:${errorDat.path}` : undefined;
    if (isNonNullString(details)) {
      notify.error({
        title: errorDat.message,
        message: details+" {0}{1}".sprintf(suffix,titleText),
      });
    } else {
      notify.error({
        title: "Error",
        message : errorDat.message+" {0}{1}".sprintf(suffix,titleText),
      });
    }
    return true;
  } 
  return false;
}
// response interceptor
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    if(!notifyError(response.data)){
      notifyError(response);
    }
    
    if (response.status === 401) {
      handle401Error(response);
    }

    return Promise.reject(new Error(response.statusText || "Error"));
  },
  (error) => {
    const errorDat = error?.response && error.response.data ? error.response.data : null;
    const status = error?.status || error?.response?.status;
    console.log("err:", errorDat && errorDat || error, " is request error with status",status,error); // for debug
    if(!notifyError(errorDat,error.status && " status:"+error?.status)){
      if(status !== 404 && !notifyError(error) && isNonNullString(error)){
        notify.error({
          message : error,
        }); 
      }
    }
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          handle401Error(error);
          break;
        case 403:
          break;
        // 404
        case 404:
          break;
        case 406:
          break;

        case 400:
          break;
        default:
        break;
      }
    }

    // throw new Error(error);
    return Promise.reject(error);
  }
);

export const AxiosContext = createContext(
  new Proxy(axios, {
    apply: () => {
      throw new Error("You must wrap your component in an AxiosProvider");
    },
    get: () => {
      throw new Error("You must wrap your component in an AxiosProvider");
    },
  })
);

export const useAxios = () => {
  return useContext(AxiosContext);
};

const transformPagination = (pagination) => {
  if (!isObj(pagination)) return {};
  const current = pagination.current
    ? pagination.current
    : pagination.defaultCurrent;
  const pageSize = pagination.pageSize
    ? pagination.pageSize
    : pagination.defaultPageSize;

  let offset = 0;
  if (current && pageSize) {
    offset = (current - 1) * pageSize;
  }
  return {
    offset,
    limit: pageSize,
    ...pagination,
  };
};

const transformFilters = (filters) => {
  if (!filters) return;
  let result = [];
  for (let key in filters) {
    if (!filters[key] || filters[key] === null) continue;
    result = [...result, [key + ":eq:" + filters[key]]];
  }
  return result;
};

const transformSorter = (sorter) => {
  if (!sorter) return;

  let result = "";
  if (sorter.field && sorter.order) {
    let order = "desc";
    if (sorter.order === "ascend") order = "asc";
    result = sorter.field + " " + order;
  }

  return result;
};

/* type listParams = {
    limit?: number;
    offset?: number;
    filter?: string[];
    order?: string;
} */
const useGetList = (key, url, pagination, filters, sorter,...rest) => {
  const axios = useAxios();
  if(sorter === RETRY){
    rest.push(RETRY_LIST_OPTIONS);
    sorter = undefined;
  }
  const service = async () => {
    let params = {};

    params = { ...transformPagination(pagination) };
    params.filter = transformFilters(filters);
    if(sorter !== undefined){
      params.order = transformSorter(sorter);
    }

    const transformRequest = (data, headers) => {};
    const data = await axios.get(`${url}`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
      transformRequest,
    });

    return data;
  };
  checkR(rest);
   if(isObj(pagination) && typeof pagination.page =='number' && typeof pagination.size =='number'){
    key = Array.isArray(key)? key : typeof key =='string'? [key] : [];
    key.push(pagination.page);
    key.push(pagination.size);
   }
  return useQuery(key, () => service(),pagination,filters,sorter,...rest);
};
export const AVOID_RETRY = "react.query.deny_use_query_retry";
export const DOES_NOT_RETRY = AVOID_RETRY;
export const RETRY = "react.query.retry_use_query";

const checkRetry = (rest)=>{
  let hasF = false;
  rest.map((r,i)=>{
    if(r === AVOID_RETRY){
       delete rest[i];
       hasF = true;
    }
  })
  if(!hasF){
    rest.push(RETRY_OPTIONS);
  }
  return rest;
}
const checkR = (rest)=>{
  rest.map((r,i)=>{
    if(r === RETRY){
       rest[i] = RETRY_LIST_OPTIONS;
    }
  })
  return rest;
}
/****
  si le 3ème paramètre est à false, les options de retry ne seront pas pris en compte
*/
const useGetOne = (key, url,...rest) => {
  const axios = useAxios();

  const service = async () => {
    if(!isNonNullString(url)) return null;
    const data = await axios.get(`${url}`);
    return data;
  };
  const enabled = !isNonNullString(url) ? false :  !isNonNullString(key) && (!Array.isArray(key) || !key?.length) ? false : true;
  //const r = !enabled ? console.error("unable to get one row for invalid key ",key," and url",url) : null;
  checkRetry(rest);
  return useQuery(key, () => service(),enabled,{handle403:true},...rest);
};

const useCreate = (key, url) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      const data = await axios.post(`${url}`, params);
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries([key]),
    }
  );
};

/**** 
  @see : https://tanstack.com/query/v4/docs/react/reference/useMutation
  
*/
export const useMutation = (mutationFn,cacheTime,mutationKey,...args)=>{
  const options = isObj(mutationFn)? Object.assign({},mutationFn) : typeof(mutationFn) =='function'? {mutationFn} : {};
  mutationFn = typeof mutationFn ==='function' ? mutationFn : undefined;
  mutationFn = mutationFn || typeof options.mutationFn == 'function' ? options.mutationFn : undefined;
  options.cacheTime = typeof cacheTime =='number' ? cacheTime : typeof options.cacheTime =='number'? options.cacheTime : undefined; 
  options.mutationKey = isNonNullString(mutationKey) && mutationKey || isNonNullString(options.mutationKey) ? options.mutationKey : undefined;
  [cacheTime,mutationKey].map((a)=>{
    isObj(a) && extendObj(true,options,a);
  }); 
  args.map((a)=>{
    isObj(a) && extendObj(true,options,a);
  });
  options.mutationFn = async (params,...rest) => {
      return await mutationFn(params,...rest);
  }
  return RQUseMutation(options);
}

const useUpdate = (key, url) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    async (item) => {
      const data = await axios.put(`${url}`, item);
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries([key]),
    }
  );
};

const useDeleteCore = (url) => {
  const axios = useAxios();
  return useMutation(async (id) => {
    const data = await axios.delete(`${url}`, { data: id });
    return data;
  });
};

const useDelete = (url) => {
  const axios = useAxios();
  return useMutation(async (id,...rest) => {
    id = id!== undefined ? String(id) : "";
    id = id ? ('/'+id.ltrim("/")) : '';
    const data = await axios.delete(`${url}${id}`,...rest);
    return data;
  });
};

const useBatch = (url) => {
  const axios = useAxios();
  return useMutation(async (ids) => {
    const data = await axios.post(`${url}`, { idList: ids });
    return data;
  });
};

export {
  useGetOne,
  useGetList,
  useUpdate,
  useCreate,
  useDelete,
  useDeleteCore,
  useBatch,
};

export default axios;

export const handle401Error = (response)=>{
  const config = !isObj(response) ? null : (isObj(response.config)? response.config : isObj(response?.response?.config)?  response.response?.config:null);
  const url = config?.url && config?.url;
  const isAuth = isNonNullString(url) && url.toLowerCase().trim().contains("/auth/login") && true;
  if(isAuth) return;
  removeToken();
  window?.localStorage?.clear();
  window.location.href = setQueryParams("/login",{callbackPath:window?.location?.pathname});
  setTimeout(()=>{
    notify.warning({
      message : i18next.t("auth.signIn.youWillRedirectDueToSignIn")
    })
  },500)
}

export function getFetcherOptions (opts,options){
  if(opts && typeof opts =="string"){
      opts = {path:opts};
   }
   opts = extendObj(true,{},opts,options);
   let {path,url,queryParams} = opts;
   url = defaultStr(url,path);
   queryParams = Object.assign({},queryParams);
   opts.fetcher = (url,opts2)=> {
    return axios({...opts2,url,method:opts2.method || "GET"});
   };
   opts.method = defaultStr(opts.method,"GET");
   if(opts.method !=='GET'){
      const body = isNonNullString(opts.body)? options.body : isNonNullString(opts.data)? opts.data : extendObj({},opts.body,opts.data);
      if(Object.size(body,true)){
         opts.data = body;
      }
   }
   opts.params = queryParams;
   opts.headers = getRequestHeaders(opts.headers)
   opts.url = setQueryParams(url,opts.queryParams);
   return opts;
}

/**** executes a remote ajax request
 * @namaspace api/fetch
 * @typedef {{url:string,path:string,fetcher:function,checkOnline:bool}} fetchOptions
 * with : fetchOptions is of the form : {
 * path : {string}: the path of the api we want to prefix,
 * url : {string} : alias to path
 * fetcher : {function} : the remote data retrieval function. by default, (url)=>fetch(url); or fetch is by default imported from the 'unfetch' package
 * queryParams : {object} : the queryString parameters to pass to the buildAPIPath function, {@link buildAPIPath}
 * }
 * @param {(string|fetchOptions)} url, 
 * - if it is a string, then url represents the absolute or relative url through which the fetch request must be executed
 * if it's an object, then url is substituted to the options parameter and one of the path or url properties of this object is used for the execution of the fetch request
 * @param {fetchOptions}, the additional options to pass to the fetch function
 */
export function fetch (url, options = {}) {
  const {fetcher,url:u,path,...opts} = getFetcherOptions(url,options);
  return fetcher(u, opts);
};

/***@function
 * @namespace api/fetch
 * function allowing to execute the ajax request of type GET 
 * the parameters are identical to those of the function {@link fetch} with the only difference that 
 * the method used (props method of the options) is GET
 */
export const get = fetch;
/***@function 
 * function allowing to execute the ajax POST request, the parameters are identical to those of the function {@link fetch} with the only difference that 
 * the method used (props method of the options) is POST
 */
export const post = (url, options = {})=> {
  options = defaultObj(options);
  options.method = 'POST';
  return fetch(url,options);
};

export const put = (url, options = {})=> {
  options = defaultObj(options);
  options.method = 'PUT';
  return fetch(url,options);
};

export const deleteFetch = (url,options)=>{
  options = defaultObj(options);
  options.method = 'DELETE';
  return fetch(url,options);
}

export const patch = (url,options)=>{
  options = defaultObj(options);
  options.method = 'PATCH';
  return fetch(url,options);
}


export {deleteFetch as delete};

export const fetchTicketPdfContent = (id,...rest) => {
  return fetch(`/api/tickets/${id}/print`,...rest);
};