import {
  useBatch,
  useCreate,
  useDelete,
  useDeleteCore,
  useGetList,
  useGetOne,
  useUpdate,
  isValidID,
  RETRY_OPTIONS,
  deleteFetch,
  patch,
} from "./request";
import { useRecoilValue } from "recoil";
import {isObj,defaultNumber,defaultObj} from "$utils";
import {setQueryParams} from "$utils/uri";

import { activeStartDate, activeEndDate, userInfos, otp } from "../store";

const preparePaginatedList = (pagination)=>{
  pagination = defaultObj(pagination);
  pagination.page = defaultNumber(pagination.page);
  pagination.offset = defaultNumber(pagination.page);
  pagination.size = defaultNumber(pagination.size,10);
  pagination.current = defaultNumber(pagination.current,1);
  return pagination;
}

export * from "./request";
//authentification

export const useLoginUser = () => {
  return useCreate("", "/api/auth/login");
};


export const useOtp = (id) => {
  return useCreate("", `/api/auth/login/${id}?otp=${useRecoilValue(otp)}`);
};

//Payment request

export const useGetPaymentRequest = (
  status,
  checker_id,
  pagination,
  filters,...rest
) => {
  let link = "";
  if (status != "" && status != null && checker_id == null) {
    link = `/api/payments?status=${status}`;
  } else if (checker_id != null && checker_id != "") {
    link = `/api/payments?checker_id=${checker_id}`;
  } else if (status && checker_id) {
    link = `/api/payments?checker_id=${checker_id}&status=${status}`;
  } else {
    link = `/api/payments`;
  }
  return useGetList("PaymentRequest", link, pagination, filters,...rest);
};
export const useAddPayRequest = (id) => {
  return useCreate("PaymentRequest", `/api/payments/ticket/${id}`);
};
export const useGetPayment = (id,...rest) => {
  return useGetOne("PaymentRequestDetails", isValidID(id) && `/api/payments/${id}`,...rest);
};

export const useAddAttachmentpayment = (id, document) => {
  return useCreate("", `/api/payments/${id}/attachment/${document}`);
};

export const useAddOtherAttachmentPayment = (id, document) => {
  return useCreate("", `/api/payments/${id}/attachment/others`);
};

export const useAddCommentPayment = (id) => {
  return useCreate("PaymentComment", `/api/payments/${id}/comment`);
};

export const useAddMutationPayment = (id, idemployee, grade) => {
  return useCreate(
    "mutation",
    `/api/payments/${id}/mutation?employee_id=${idemployee}&step=${grade}`
  );
};

export const useAddVerifyPayment = (id) => {
  return useCreate("VerifyPayment", `/api/payments/${id}/verify`);
};

export const useAddPaymentSpending = (id) => {
  return useCreate("PaymentSpending", `/api/payments/${id}/items`);
};

export const useUpdatePaymentSpending = (id) => {
  return useUpdate("PaymentSpendingUpdate", `/api/payments/${id}/items`);
};

export const useAddPaymentInfo = (id) => {
  return useUpdate("PaymentInfoUpdate", `/api/payments/${id}`);
};

export const useDeletePaymentSpending = (id) => {
  return useDeleteCore(`/api/payments/${id}/items`);
};

export const useDeletePaymentAttachment = (id) => {
  return useDelete(`/api/payments/${id}/attachments`);
 };
//tickets

export const useGetAllTickets = (status, checker_id, pagination, filters,...rest) => {
  let link = "";
  if (status != "" && status != null && checker_id == null) {
    link = `/api/tickets?status=${status}`;
  } else if (checker_id != null && checker_id != "") {
    link = `/api/tickets?checker_id=${checker_id}`;
  } else if (status && checker_id) {
    link = `/api/tickets?checker_id=${checker_id}&status=${status}`;
  } else {
    link = `/api/tickets`;
  }
  return useGetList("Tickets", link, preparePaginatedList(pagination), filters,...rest);
};

export const useGetValidTickets = (searchKey, pagination, filters, ...rest) => {
  // Ne pas faire d'appel API si searchKey est vide
  const endpoint = searchKey?.trim() 
    ? `/api/tickets/codes/status/5?search=${encodeURIComponent(searchKey)}&page=${pagination.page}&size=${pagination.size}`
    : null;

  return useGetList(
    "TicketsValid",
    endpoint,
    preparePaginatedList(pagination),
    filters,
    {
      ...rest,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      cacheTime: 0,
      staleTime: 0,
      enabled: !!searchKey?.trim()  // Activer seulement si searchKey n'est pas vide
    }
  );
};


export const useAddTicketRemark = (id, remark) => {
  return useUpdate(
    "TicketRemark",
    `/api/tickets/${id}/quotation?remark=${remark}`
  );
};

export const useAddTicketSpending = (id) => {
  return useCreate("TicketSpending", `/api/tickets/${id}/items`);
};
export const useUpdateTicketSpending = (id) => {
  return useUpdate("TicketSpendingUpdate", `/api/tickets/${id}/items`);
};

export const useDeleteAttachment = (id) => {
  return useDelete(`/api/tickets/${id}/attachments`);
};

export const useAddAnalRemark = (id, remark) => {
  return useUpdate(
    "AnalRemark",
    `/api/analytics/${id}/quotation?remark=${remark}`
  );
};

export const useDeleteTicketSpending = (id) => {
  return useDeleteCore(`/api/tickets/${id}/items`);
};

//Expenses Commitment

export const useGetExpensesCommitment = (pagination, filters,...rest) => {
  return useGetList("ExpensesCommitment", `/api/demands`, pagination, filters,...rest);
};

export const useGetTickets = (pagination, filters, id,...rest) => {
  return useGetList(
    "ExpensesCommitment",
    `/api/tickets/checking/list/${id}`,
    pagination,
    filters,...rest
  );
};

export const useGetExpensesCommitmentDetails = (id,...rest) => {
  return useGetOne("CommitmentDetails", isValidID(id) && `/api/demands/${id}`,...rest);
};

export const useGetExpensesCommitmentTicket = (id,...rest) => {
  return useGetOne("CommitmentTicket",isValidID(id) && `/api/tickets/${id}`,...rest);
};

export const useAddCommitment = () => {
  return useCreate("ExpensesCommitment", `/api/demands`);
};

export const useAddMutation = (id, idemployee, grade) => {
  return useCreate(
    "mutation",
    `/api/tickets/${id}/mutation?employee_id=${idemployee}&step=${grade}`
  );
};

export const useAddAttachmentDemand = (id, document) => {
  return useCreate("", `/api/demands/${id}/attachment/${document}`);
};

export const useAddAttachmentTicket = (id, document) => {
  return useCreate("", `/api/tickets/${id}/attachment/${document}`);
};

export const useAddOtherAttachmentTicket = (id, document) => {
  return useCreate("", `/api/tickets/${id}/attachment/others`);
};

export const useAddProcedure = (id, procedure) => {
  return useCreate("", `/api/tickets/${id}/procedure/${procedure}`);
};

export const useGetAttachmentDownload = (id,...rest) => {
  return useGetOne("Attachment", isValidID(id) && `/api/attachments/${id}/download`,...rest);
};

export const useAddCommentTicket = (id,...rest) => {
  return useCreate("ExpensesComment", isValidID(id) && `/api/tickets/${id}/comment`,...rest);
};

export const useAddAnalytic = () => {
  return useUpdate("CommitmentDetails", `/api/items`);
};

export const useAddVerify = (id) => {
  return useCreate("Verify", `/api/tickets/${id}/verify`);
};
export const useAddStatus = (id, status) => {
  return useCreate("StatusCode", `/api/tickets/${id}/change-status/${status}`);
};

export const useAddClose = (id) => {
  return useCreate("CloseTicket", `/api/tickets/${id}/close`);
};

export const useAddSubmitAgain = (id) => {
  return useCreate("SubmitAgain", `/api/tickets/${id}/submit`);
};

export const useAddSubmitAgainPayment = (id) => {
  return useCreate("SubmitAgain", `/api/payments/${id}/submit`);
};

export const useAddStatusPayment = (id) => {
  return useCreate("StatusCode", `/api/payments/${id}/close`);
};
// employees

export const useGetEmployees = (pagination,filters,...rest) => {
  return useGetList("Employees", "/api/employees", pagination,filters,...rest);
};

export const useGetAllEmployees = (pagination,filters,...rest) => {
  return useGetList("Employees", "/api/employees/page", preparePaginatedList(pagination),filters,...rest);
};

export const useAddEmployee = () => {
  return useCreate("Employees", `/api/auth/register`);
};

export const useGetEmployee = (id,...rest) => {
  return useGetOne("Employee", isValidID(id) && `/api/employees/${id}`,...rest);
};

export const useEditEmployee = (id,...rest) => {
  return useUpdate("Employees", isValidID(id) && `/api/employees/${id}`,...rest);
};

export const useEditCred = (id) => {
  return useUpdate("EmployeesCred", `/api/users/${id}`);
};
export const useUpdatePassword = (id) => {
  return useUpdate("EmployeesU", `/api/users/${id}`);
};

export const useGetEmployeeSignature = (id,...rest) => {
  return useGetOne("EmployeeSign", isValidID(id) && `/api/employees/${id}/signature`,...rest);
};

export const useAddSignature = (id,...rest) => {
  return useCreate("", `/api/employees/${id}/signature`,...rest);
};
export const useDeleteAccount = () => {
  return useDelete(`/api/employees`);
};

// projects

/*export const useGetProjects = (pagination,filters,...rest) => {
    return useGetList(
        "Project",
        "/api/projects",
        pagination,
        filters
    );
}*/

/* export const useGetUserInfos = () => {
    return useGetOne(
        "UserInfos",
        "/employees/details"

      );
}

export const useUpdateUser = () => {
    return useUpdate("/employees/changeprofile");
}

export const useUpdatePassword = () => {
    return useUpdate("/employees/change-password");
} */

// project

export const useGetProjects = (pagination,filters,...rest) => {
  return useGetList("Projects", "/api/projects", pagination,filters,...rest);
};

export const useGetAllProjects = (pagination,filters,...rest) => {
  return useGetList("Projects", "/api/projects/page", pagination,filters,...rest);
};

export const useGetProject = (id,...rest) => {
  return useGetOne("Project", isValidID(id) && `/api/projects/${id}`,...rest);
};

export const useAddProjects = () => {
  return useCreate("Projects", "/api/projects");
};

export const useEditProject = (id) => {
  return useUpdate("Projects", `/api/projects/${id}`);
};

export const useDeleteProject = () => {
  return useDelete("/api/projects");
};

/*export const useAddActivity = (id) => {
    return useCreate("Activities",`/api/projects/${id}/activities`);
}*/

//Services & sub

export const useGetService = (pagination,filters,...rest) => {
  return useGetList("Services", "/api/services", pagination,filters,...rest);
};

export const useGetDocs = (pagination,filters,...rest) => {
  return useGetList("DocumentsAll", "/api/documents", pagination,filters,...rest);
};

export const useGetCatLogistic = (pagination,filters,...rest) => {
  return useGetList("CatLogistic", "/api/categories", pagination,filters,...rest);
};

// Zones

export const useGetZones = (pagination,filters,...rest) => {
  return useGetList("Zones", "/api/zones", pagination,filters,...rest);
};

export const useGetAllZones = (pagination,filters,...rest) => {
  return useGetList("Zones", "/api/zones/page", preparePaginatedList(pagination),filters,...rest);
};



export const useAddZone = () => {
  return useCreate("Zones", "/api/zones");
};

export const useEditZone = (id) => {
  return useUpdate("Zones", `/api/zones/${id}`);
};

export const useDeleteZone = () => {
  return useDelete("/api/zones");
};
// Zones

export const useGetActivities = (pagination,filters,...rest) => {
  return useGetList("Activities", "/api/activities", pagination,filters,...rest);
};

export const useAddActivity = () => {
  return useCreate("Activities", "/api/activities");
};

export const useUpdateActivity = (id) => {
  return useCreate("Activities", `/api/activities/${id}`);
};

export const useDeleteActivity = () => {
  return useDelete("/api/activities");
};

// branch

export const useGetUserBranch = (pagination, filters,...rest) => {
  return useGetList(
    "Userbranch",
    `/employees/${useRecoilValue(userInfos).id}/branches`,
    pagination,
    filters,...rest
  );
};

// Zones

export const useGetAffectations = (pagination,filters,...rest) => {
  return useGetList("Affectations", "/api/affectations", pagination,filters,...rest);
};

export const useGetAllAffectations = (pagination,filters,...rest) => {
  return useGetList("Affectations", "/api/affectations/page", pagination,filters,...rest);
};

export const useAddAffectation = () => {
  return useCreate("Affectations", "/api/affectations");
};

export const useEditAffectation = (id) => {
  return useUpdate("Affectations", `/api/affectations/${id}`);
};

export const useDeleteAffectation = () => {
  return useDelete("/api/affectations");
};

export const useGetMovements = (id, pagination,filters,...rest) => {
  return useGetList(
    "MovementsCheck",
    `/api/movements/checking/list/${id}`,
    pagination,
    filters,...rest
  );
};

export const useGetAllMovements = (status, checker_id, pagination,filters,...rest) => {
  let link = "";
  if (status != "" && status != null && checker_id == null) {
    link = `/api/movements?status=${status}`;
  } else if (checker_id != null && checker_id != "") {
    link = `/api/movements?checker_id=${checker_id}`;
  } else if (status && checker_id) {
    link = `/api/movements?checker_id=${checker_id}&status=${status}`;
  } else {
    link = `/api/movements`;
  }
  return useGetList("Movements", link, preparePaginatedList(pagination), filters,...rest);
};

export const useAddMovement = () => {
  return useCreate("Movements", "/api/movements");
};

export const useEditMovement = (id) => {
  return useUpdate("Movements", `/api/movements/${id}`);
};

export const useGetMovement = (id,...rest) => {
  return useGetOne("Movements", isValidID(id) && `/api/movements/${id}`,...rest);
};

export const useAddMember = (id) => {
  return useCreate("Members", `/api/movements/${id}/add/members`);
};

export const useAddCommentForMovement = (id) => {
  return useCreate("Comments", `/api/movements/${id}/comment`);
};

export const useAddExternalMember = (id) => {
  return useCreate(
    "ExternalMembers",
    `/api/movements/${id}/add/external/members`
  );
};

export const useValidateMovement = (movement_id) => {
  return useCreate("ExternalMembers", `/api/movements/${movement_id}/verify`);
};

export const useAutoriseMovement = (movement_id) => {
  return useCreate(
    "ExternalMembers",
    `/api/movements/${movement_id}/verify/?complete=1`
  );
};

export const useDeleteMovementAttachment = (id) => {
  return useDelete(`/api/movements/${id}/attachment`);
};

export const useDeleteMovementExternalMember = (id,...rest) => {
  return useDelete(`/api/movements/${id}/remove`,...rest);
};
export const useDeleteMovementMember = (id,...rest) => {
  return useDelete(`/api/movements/${id}/remove`,...rest);
};


export const deleteMovementExternalMember = (movementId,memberId,...rest) => {
  return useDelete(`api/movements/${movementId}/remove/${memberId}/externalMembers`,...rest);
};

export const useChangePerson = (movement_id, employee_id, grade) => {
  return useCreate(
    "ExternalMembers",
    `/api/movements/${movement_id}/mutation?employee_id=${employee_id}&step=${grade}`
  );
};

export const useGetDocumentsTypes = (pagination,filters,...rest) => {
  return useGetList("Documents", `/api/documents`, pagination,filters,...rest);
};

export const useAddDocument = (id, document) => {
  return useCreate("Documents", `/api/movements/${id}/attachment/${document}`);
};


/*export const useGetRejectedTicket = (pagination,filters,...rest) => {
    return useGetList(
        "Documents",
        `/api/documents`,
        pagination,
        filters,...rest
    );
}*/

export const useGetRejectedTicketForDashboard = (pagination,filters,...rest) => {
  return useGetList(
    "RejectedTicket",
    `/api/statistics/count/monthly/rejected/ticket`,
    pagination,
    filters,...rest
  );
};

export const useGetCreatedTicketForDashboard = (pagination,filters,...rest) => {
  return useGetList(
    "CreatedTicket",
    `/api/statistics/count/monthly/created/ticket`,
    pagination,
    filters,...rest
  );
};

export const useGetApprovedTicketForDashboard = (pagination, filters,...rest) => {
  return useGetList(
    "ApprovedTicket",
    `/api/statistics/count/monthly/approved/ticket`,
    pagination,
    filters,...rest
  );
};

export const useGetMetricsForDashboard = (pagination,filters,...rest) => {
  return useGetList("Metrics", `/api/statistics/metrics`, pagination,filters,...rest,RETRY_OPTIONS);
};

export const useGetApprobationMovement = (pagination,filters,...rest) => {
  return useGetList(
    "MovementDuration",
    `/api/statistics/average/monthly/approbation/duration/movement`,
    pagination,
    filters,...rest
  );
};

export const useGetApprobationTicket = (pagination,filters,...rest) => {
  return useGetList(
    "MovementDuration",
    `/api/statistics/average/monthly/approbation/duration/ticket`,
    pagination,
    filters,...rest
  );
};

export const useGetApprobationPayment = (pagination,filters,...rest) => {
  return useGetList(
    "MovementDuration",
    `/api/statistics/average/monthly/approbation/duration/payment`,
    pagination,
    filters,...rest
  );
};

// Post

export const useGetPosts = (pagination,filters,...rest) => {
  return useGetList("Posts", "/api/posts", pagination,filters,...rest);
};
export const useGetAllPosts = (pagination,filters,...rest) => {
  return useGetList("Posts", "/api/posts/page", preparePaginatedList(pagination),filters,...rest);
};

export const useAddPosts = () => {
  return useCreate("Posts", "/api/posts");
};

export const useEditPosts = (id) => {
  return useUpdate("Posts", `/api/posts/${id}`);
};

export const useDeletePosts = () => {
  return useDelete("/api/posts");
};



export const useGetNotifications = (...rest) => {
  return useGetList("Notifications", "/api/notifications", null,null,...rest,null,RETRY_OPTIONS);
};

export const deleteNotifications = (notifications,rest)=>{
  rest = isObj(rest)? rest : {};
  const data = Array.isArray(notifications)? notifications : typeof notifications == 'number'? [notifications] : [];
  if(!data.length) return Promise.reject({message:'not notification to delete'});
  return deleteFetch(setQueryParams("/api/notifications",{ids:data.join(",")}),rest)
}
